import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Skeleton,
  Spinner,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  ApiRequestPriority,
  ApiRequestStatus,
  ApiRequestType,
  ApiWorkflowField,
  ApiWorkflowSchemaField,
  CreateApiRequest,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import {
  HorizontalStepItem,
  HorizontalSteps,
} from "../../../components/horizontal-steps/HorizontalSteps";
import { useHorizontalStep } from "../../../components/horizontal-steps/useHorizontalSteps";
import { RootState, useThunkDispatch } from "../../../store";
import {
  addScheduledRequestToList,
  updateScheduledRequestInList,
  updateScheduleInList,
} from "../../../store/schedule-request-list.slice";
import {
  addScheduledRequest,
  ScheduledRequestProps,
  unloadSchedulesForm,
} from "../../../store/scheduled-request-form/schedule-request-form.slice";
import {
  createScheduledRequest,
  getScheduledRequestFormValues,
  initScheduleRequestFormValues,
  updateScheduledRequest,
} from "../../../store/scheduled-request-form/thunks";
import { debounce } from "../../../utils/debounce";
import { getVisibleFields } from "../../../utils/getVisibleFields";
import { ScheduleAssetsForm } from "./ScheduledAssetsForm";
import { ScheduledRequestForm } from "./ScheduledRequestForm";
import { ScheduleForm } from "./ScheduleForm";
import { validateScheduleRequestForm } from "./ScheduleFormsSchemas";
import { ScheduledTaskBooksForm } from "./SheduledTaskbooksForm";

interface ScheduleFormProps {
  isOpen: boolean;
  onClose: () => void;
  workingScheduledRequestId: string | undefined;
  setWorkingScheduledRequestId?: Dispatch<SetStateAction<undefined | string>>;
}

export const ScheduleForms: FC<ScheduleFormProps> = ({
  isOpen,
  onClose,
  workingScheduledRequestId,
  setWorkingScheduledRequestId,
}) => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { currentUser, currentAccount, apiClient, isProductAdmin } =
    useAuthentication();
  const toast = useToast();
  const {
    activeStep,
    isLastStep,
    getStepState,
    gotoStep,
    progress,
    setTotalSteps,
    resetSteps,
    totalSteps,
  } = useHorizontalStep();
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const {
    isLoading,
    willRequestRepeat,
    scheduledRequest,
    scheduledRequestAssets,
    scheduledRequestTaskBooks,
  } = useSelector((state: RootState) => state.scheduleRequestForm);

  const { workflowMap } = useSelector((state: RootState) => state.localCache);

  const modalBackground = useColorModeValue("white", "blue.900");
  const cancelButtonColor = useColorModeValue("blue.500", "white");
  const submitButtonColor = useColorModeValue("blue.500", "whiteAlpha.300");
  const [disableNextbutton, setDisableNextButton] = useState(true);
  const [request, setRequest] = useState<ScheduledRequestProps | null>(
    scheduledRequest
  );
  const [spinnerLoad, setSpinnerLoad] = useState<boolean>(false);

  const [engine, setEngine] = useState<SchemaRulesEngine>();
  const [schemaFields, setSchemaFields] = useState<ApiWorkflowSchemaField[]>();
  const [visibleFields, setVisibleFields] = useState<ApiWorkflowField[]>();

  const { policyMap } = useSelector((state: RootState) => state.localCache);

  const { showTaskbooks } = useMemo(() => {
    if (!visibleFields) return { showTaskbooks: false };
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const getSchemaFields = useCallback(() => {
    if (!scheduledRequest.workflow) return;
    apiClient
      .findWorkflowSchemaFields(
        currentAccount.id,
        scheduledRequest.workflow.schema.id,
        {
          pageSize: 50,
        }
      )
      .then((response) => setSchemaFields(response.data));
  }, [apiClient, currentAccount.id, scheduledRequest.workflow]);

  const isEdit = useMemo(() => {
    return workingScheduledRequestId ? true : false;
  }, [workingScheduledRequestId]);

  const showAssetsForm = useMemo(() => {
    if (!scheduledRequest || !scheduledRequest.workflow) {
      return false;
    }
    // inactive workflows would fail this lookup
    const workflow = workflowMap[scheduledRequest.workflow.id];

    return (
      (workflow && workflow.allowAssets) || scheduledRequestAssets.length > 0
    );
  }, [workflowMap, scheduledRequest, scheduledRequestAssets]);

  const showTaskbooksForm = useMemo(() => {
    if (!scheduledRequest || !scheduledRequest.workflow) {
      return false;
    }
    // inactive workflows would fail this lookup
    const workflow = workflowMap[scheduledRequest.workflow.id];

    return (workflow && showTaskbooks) || scheduledRequestTaskBooks.length > 0;
  }, [workflowMap, scheduledRequest, scheduledRequestTaskBooks, showTaskbooks]);

  const setScheduledRequestData = useCallback(() => {
    if (request) {
      dispatch(addScheduledRequest(request));
    }
  }, [dispatch, request]);

  const scheduledRequestToast = useCallback(
    (createOrUpdate?: "create" | "update") => {
      if (createOrUpdate) {
        return toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "success",
          title: `Schedule has been ${createOrUpdate}d succesfully`,
        });
      }
      return toast({
        duration: 1500,
        isClosable: true,
        position: "top",
        status: "error",
        title: "Error saving scheduled request data",
      });
    },
    [toast]
  );

  const handleOnCloseScheduleForms = useCallback(() => {
    onClose();
    resetSteps();
    setWorkingScheduledRequestId && setWorkingScheduledRequestId(undefined);
    dispatch(unloadSchedulesForm());
  }, [dispatch, onClose, resetSteps, setWorkingScheduledRequestId]);

  const handleOnClickSaveData = useCallback(() => {
    if (!scheduledRequest.id) {
      thunkDispatch(
        createScheduledRequest({
          accountId: currentAccount.id,
          apiClient: apiClient,
        })
      )
        .then(unwrapResult)
        .then((createdScheduledRequest) => {
          createdScheduledRequest &&
            dispatch(addScheduledRequestToList(createdScheduledRequest));
          scheduledRequestToast("create");
        })
        .catch(() => {
          scheduledRequestToast();
        })
        .finally(() => {
          setSpinnerLoad(false);
          handleOnCloseScheduleForms();
        });
    } else {
      thunkDispatch(
        updateScheduledRequest({
          accountId: currentAccount.id,
          apiClient: apiClient,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          if (res) {
            const { updatedScheduledRequest, scheduleAssociation } = res;
            updatedScheduledRequest &&
              dispatch(updateScheduledRequestInList(updatedScheduledRequest));
            scheduleAssociation &&
              dispatch(updateScheduleInList(scheduleAssociation));
            scheduledRequestToast("update");
          }
        })
        .catch(() => {
          scheduledRequestToast();
        })
        .finally(() => {
          setSpinnerLoad(false);
          handleOnCloseScheduleForms();
        });
    }
  }, [
    scheduledRequest.id,
    thunkDispatch,
    currentAccount.id,
    apiClient,
    dispatch,
    scheduledRequestToast,
    handleOnCloseScheduleForms,
  ]);

  const handleOnClickNext = useCallback(
    (currentStep: number, isLastStep?: boolean) => {
      isLastStep && setSpinnerLoad(true);
      currentStep === 1 && setScheduledRequestData();
      isLastStep && handleOnClickSaveData();
      gotoStep(activeStep)();
    },
    [setScheduledRequestData, handleOnClickSaveData, gotoStep, activeStep]
  );

  const handleOnChangeFormikValues = useCallback(
    (values: ScheduledRequestProps) => {
      const isValid = validateScheduleRequestForm(values);
      if (disableNextbutton !== !isValid) {
        setDisableNextButton(!isValid);
      }
      setRequest(values);
    },
    [disableNextbutton]
  );

  const debouncedChangeFormikValues = debounce(handleOnChangeFormikValues, 200);

  useEffect(() => {
    if (isOpen) {
      if (!workingScheduledRequestId) {
        thunkDispatch(
          initScheduleRequestFormValues({
            apiClient,
            currentUser,
            currentAccount,
          })
        );
      } else {
        thunkDispatch(
          getScheduledRequestFormValues({
            apiClient,
            accountId: currentAccount.id,
            scheduledRequestId: workingScheduledRequestId,
          })
        );
      }
    }
  }, [
    thunkDispatch,
    isOpen,
    apiClient,
    isLoading,
    currentUser,
    currentAccount,
    workingScheduledRequestId,
  ]);

  useEffect(() => {
    getSchemaFields();
  }, [getSchemaFields]);

  useEffect(() => {
    if (
      !scheduledRequest ||
      !scheduledRequest.workflow ||
      !scheduledRequest.workflow.id ||
      !schemaFields
    ) {
      return;
    }

    const policy = policyMap[scheduledRequest.workflow.id];

    const rulesEngine =
      engine ||
      new SchemaRulesEngine({
        account: currentAccount,
        user: currentUser,
        form: "full",
        schemaFields: schemaFields,
        workflow: scheduledRequest.workflow,
        policy: policy,
        isProductAdmin,
      });

    if (!engine) {
      setEngine(rulesEngine);
    }
    const fields = rulesEngine.getVisibleFields({
      request: {
        ...scheduledRequest,
        scheduledRequestId: scheduledRequest.id,
        metadata: {},
        type: ApiRequestType.scheduled,
        status: ApiRequestStatus.new,
        priority: ApiRequestPriority.standard,
        scheduling: {
          start: null,
          due: null,
          completed: null,
        },
      } as CreateApiRequest,
      includeDeleted: false,
    });

    setVisibleFields(fields);
  }, [
    currentAccount,
    currentUser,
    engine,
    isProductAdmin,
    request,
    schemaFields,
    dispatch,
    policyMap,
    scheduledRequest,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      autoFocus={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={handleOnCloseScheduleForms}
    >
      <ModalOverlay />
      <ModalContent
        minW={["90%", "80%", "80%", "748px"]}
        maxW={["90%", "80%", "80%", "748px"]}
        bgColor={modalBackground}
      >
        <ModalBody width="100%" p={1}>
          {spinnerLoad ? (
            <Center py={8}>
              <Spinner size="xl" />
            </Center>
          ) : isLoading ? (
            <Stack>
              <Skeleton height="550px" />
            </Stack>
          ) : (
            <HorizontalSteps
              activeStep={activeStep}
              getStepState={getStepState}
              gotoStep={gotoStep}
              progress={progress}
              setTotalSteps={setTotalSteps}
              saveCallback={activeStep === 1 ? handleOnClickNext : undefined}
              disableStepButtons={disableNextbutton}
            >
              <HorizontalStepItem>
                <ScheduledRequestForm
                  debouncedChangeFormikValues={debouncedChangeFormikValues}
                  isEdit={isEdit}
                  activeStep={activeStep}
                  totalSteps={totalSteps}
                />
              </HorizontalStepItem>

              {showTaskbooksForm && (
                <HorizontalStepItem>
                  <ScheduledTaskBooksForm
                    isEdit={isEdit}
                    activeStep={activeStep}
                    totalSteps={totalSteps}
                  />
                </HorizontalStepItem>
              )}

              {showAssetsForm && (
                <HorizontalStepItem>
                  <ScheduleAssetsForm
                    isEdit={isEdit}
                    activeStep={activeStep}
                    totalSteps={totalSteps}
                  />
                </HorizontalStepItem>
              )}

              {willRequestRepeat && (
                <HorizontalStepItem>
                  <ScheduleForm
                    isEdit={isEdit}
                    activeStep={activeStep}
                    totalSteps={totalSteps}
                  />
                </HorizontalStepItem>
              )}
            </HorizontalSteps>
          )}
        </ModalBody>
        <ModalFooter mt={4} mb={8} display="block" textAlign="center">
          <Button
            mr={10}
            variant="ghost"
            size={isMobile ? "sm" : "md"}
            onClick={handleOnCloseScheduleForms}
            color={cancelButtonColor}
          >
            Cancel
          </Button>

          <Button
            color="white"
            disabled={disableNextbutton}
            size={isMobile ? "sm" : "md"}
            minW={isMobile ? "120px" : "180px"}
            _hover={{ backgroundColor: "none" }}
            bgColor={submitButtonColor}
            onClick={() => handleOnClickNext(activeStep, isLastStep)}
          >
            {isLastStep ? "Save" : "Next"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
