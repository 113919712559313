import {
  Avatar,
  AvatarGroup,
  AvatarGroupProps,
  AvatarProps,
  Tag,
  TagLabel,
  TagLabelProps,
  TagProps,
  Text,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { useMemo } from "react";

type UsersBadgeProps = {
  nullId?: string;
  nullLabel?: string;
  noItemsLabel?: string;
  value: ApiUserSummary | ApiUserSummary[];
  tooltip?: boolean;
  avatar?: boolean;
  label?: boolean;
  tagProps?: TagProps;
  avatarProps?: AvatarProps;
  avatarGroupProps?: AvatarGroupProps;
  tagLabelProps?: TagLabelProps;
  tooltipProps?: TooltipProps;
};
export const UsersBadge = ({
  noItemsLabel = "-",
  nullId = "null-user",
  nullLabel = "",
  tooltip = true,
  avatar = true,
  label = true,
  tagProps,
  avatarProps,
  tooltipProps,
  tagLabelProps,
  avatarGroupProps,
  value,
}: UsersBadgeProps) => {
  const users = useMemo(
    () => (Array.isArray(value) ? value : [value]),
    [value]
  );

  const singleUser: ApiUserSummary = useMemo(() => {
    if (!users || users.length === 0) {
      return {
        firstName: nullLabel || "No User",
        lastName: "",
        id: nullId,
        email: "",
        phone: "",
        profileImage: "",
        timeZone: null,
      };
    } else {
      return users[0];
    }
  }, [users, nullId, nullLabel]);

  const fullName = useMemo(() => {
    return `${singleUser.firstName} ${singleUser.lastName}`;
  }, [singleUser.firstName, singleUser.lastName]);

  const MultiUserBadge = () => {
    return (
      <AvatarGroup max={3} size="xs" spacing={-1} {...avatarGroupProps}>
        {users.map((v) => (
          <Avatar
            key={v.id}
            src={v.profileImage}
            name={`${v.firstName} ${v.lastName}`}
          />
        ))}
      </AvatarGroup>
    );
  };

  if (users.length === 0) {
    return (
      <Tooltip
        isDisabled={tooltip === false}
        label={noItemsLabel}
        placement="auto-start"
        {...tooltipProps}
      >
        <Text> {noItemsLabel} </Text>
      </Tooltip>
    );
  }

  if (users.length === 1) {
    return (
      <Tooltip
        placement="auto-start"
        isDisabled={tooltip === false}
        label={`${singleUser.firstName} ${singleUser.lastName} - ${singleUser.email}`}
        {...tooltipProps}
      >
        <Tag size="lg" background="inherit" pl={0} pr={1} {...tagProps}>
          {avatar ? (
            <Avatar
              size="xs"
              name={fullName}
              src={singleUser.profileImage}
              visibility={singleUser.id === nullId ? "hidden" : "initial"}
              ml={-1}
              mr={2}
              {...avatarProps}
            />
          ) : null}

          {label ? (
            <TagLabel
              fontWeight={"normal"}
              fontStyle={singleUser.id === nullId ? "italic" : "normal"}
              fontSize={[16, 16, 14]}
              {...tagLabelProps}
            >
              {nullId && nullLabel ? nullLabel : fullName}
            </TagLabel>
          ) : null}
        </Tag>
      </Tooltip>
    );
  }

  if (users.length > 1) {
    return (
      <Tooltip
        placement="auto-start"
        isDisabled={tooltip === false}
        {...tooltipProps}
      >
        <MultiUserBadge />
      </Tooltip>
    );
  }

  return null;
};
