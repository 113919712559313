import {
  Box,
  Flex,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Label } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";

interface LabelsSectionProps {
  value: Label[] | null;
  setValues: (labels: Label[]) => void;
  type: "location" | "asset";
}

export const LabelsSection: FC<LabelsSectionProps> = ({
  value,
  setValues,
  type,
}) => {
  const [labels, setLabels] = useState<Label[]>([{ key: "", value: "" }]);
  const errorTextColor = useColorModeValue("red.500", "red.300");

  const onChangeText = useCallback(
    (text: string, index: number, key: boolean) => {
      if (index === labels.length - 1 || labels.length === 1) {
        let newValue = undefined;
        if (key) {
          newValue = { key: text, value: labels[index].value };
        } else {
          newValue = { key: labels[index].key, value: text };
        }
        labels.splice(labels.length - 1, 0, newValue);
      } else {
        let empty = text === "";
        if (key) {
          empty = labels[index].value === "" && empty;
        } else {
          empty = labels[index].key === "" && empty;
        }
        if (empty) {
          labels.splice(index, 1);
        } else {
          let newValue = undefined;
          if (key) {
            newValue = { key: text, value: labels[index].value };
          } else {
            newValue = { key: labels[index].key, value: text };
          }
          labels[index] = newValue;
        }
      }
      setLabels([...labels]);
      const res = [...labels];
      res.pop();
      setValues(res);
    },
    [labels, setValues]
  );

  useEffect(() => {
    let newValue: Label[] = [];
    if (value) {
      newValue = [...value];
      newValue.push({ key: "", value: "" });
      setLabels(newValue);
    }
  }, [value]);

  return (
    <Box>
      <HStack>
        <Box>
          <Text>Labels</Text>
          <Text fontSize="xs" color="gray.500">
            {`Use labels to add extra information about the ${type}.`}
          </Text>
          <Text fontSize="xs" color="gray.500">
            Keys are not required to be unique.
          </Text>{" "}
        </Box>
      </HStack>
      {labels.map((label, index) => (
        <Flex key={`label_key_value${index}`} gap={[2, 6]} w="100%" mt={[2, 6]}>
          <Box w="100%">
            <Input
              id={`key${index}`}
              className="key"
              placeholder="Enter a Label"
              value={label.key}
              onChange={(element) => {
                onChangeText(element.target.value, index, true);
              }}
              isInvalid={label.key === "" && index !== labels.length - 1}
            />
            {label.key === "" && index !== labels.length - 1 && (
              <Text fontSize="sm" textColor={errorTextColor}>
                key is a required field
              </Text>
            )}
          </Box>
          <Box w="100%">
            <Input
              id={`value${index}`}
              className="value"
              placeholder="Enter a Value"
              value={label.value}
              onChange={(element) => {
                onChangeText(element.target.value, index, false);
              }}
              isInvalid={label.value === "" && index !== labels.length - 1}
            />
            {label.value === "" && index !== labels.length - 1 && (
              <Text fontSize="sm" textColor={errorTextColor}>
                value is a required field
              </Text>
            )}
          </Box>
        </Flex>
      ))}
    </Box>
  );
};
