import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiEvent,
  ApiLocation,
  ApiProject,
  ApiReason,
  ApiReportingCategory,
  ApiRequestPriority,
  ApiRequestStatus,
  ApiRequestType,
  ApiScheduledRequest,
  ApiService,
  ApiWorkflow,
} from "@operations-hero/lib-api-client";
import React, { useCallback, useEffect, useMemo } from "react";
import { FiColumns } from "react-icons/fi";
import { MdOutlineTableRows } from "react-icons/md";
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import {
  LocationFilter,
  LocationFilterValue,
} from "../../components/filters/LocationFilter";
import { useProductSubscriptions } from "../../components/shell/AppShell";
import { useRequestsAccessForUser } from "../../hooks/useRequestsAccessForUser";
import { RootState } from "../../store";
import {
  cleanAllFilters,
  updateRequestFilters,
} from "../../store/request-list.slice";
import { switchMode } from "../../store/requests.slice";
import { useCategoryUtils } from "../../utils/categoryUtils";
import { useLocationUtils } from "../../utils/locationUtils";
import { WORKFLOW_FILTERS } from "../landing-page/requests/LandingPage";
import { AssetFilter } from "./filters/AssetFilter";
import { CategoryFilter } from "./filters/CategoryFilter";
import { EventFilter } from "./filters/EventFilter";
import { FiltersSelector } from "./filters/FiltersSelector";
import {
  PersonFilter,
  PersonFilterReferenceValue,
} from "./filters/PersonFilter";
import { ProjectFilter } from "./filters/ProjectFilter";
import { ReasonFilter } from "./filters/ReasonFilter";
import { RequestDateFilter } from "./filters/RequestDateFilter";
import { RequestPriorityFilter } from "./filters/RequestPriorityFilter";
import { RequestSortFilter } from "./filters/RequestSortFilter";
import { RequestStatusFilter } from "./filters/RequestStatusFilter";
import { ScheduledRequestFilter } from "./filters/ScheduledRequestFilter";
import { ServiceFilter } from "./filters/ServiceFilter";
import { TypeFilter } from "./filters/TypeFilter";
import { WorkflowFilter } from "./filters/WorkflowFilter";

interface MoreFiltersProps {
  [key: string]: JSX.Element;
}

const MORE_FILTERS = ["assets", "reasons", "types", "schedules"];

export interface FiltersWithCloseIcon {
  showCloseIcon: boolean;
  handleClickCloseIcon: (
    e: React.MouseEvent<SVGElement>,
    filterName: string
  ) => void;
}

export const RequestAdvancedFilters = () => {
  const { isProductAdmin } = useAuthentication();
  const dispatch = useDispatch();

  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = !isMobileMode && !isTablet ? true : false;

  const { initCompleted } = useSelector(
    (state: RootState) => state.requestList
  );

  const { displayMode } = useSelector(
    (state: RootState) => state.requestsSlice
  );

  const {
    persons,
    statuses,
    priorities,
    workflows,
    categories,
    reasons,
    types,
    locations,
    search,
    assets,
    events,
    services,
    scheduledRequest,
    projects,
    moreFilters,
  } = useSelector((state: RootState) => state.requestList.filters);

  const {
    policyMap,
    workflowMap,
    locationMap,
    locations: locationsLocalCache,
  } = useSelector((state: RootState) => state.localCache);

  const { hasEvents, hasPlanning } = useProductSubscriptions();

  const { getChildrenId } = useLocationUtils();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();
  const bgColor = useColorModeValue("white", "whiteAlpha.300");

  const allowedLocations = useMemo(() => {
    const workflows: string[] = [];
    const locations: ApiLocation[] = [];
    let locationsIds: string[] = [];

    Object.entries(workflowMap).map(([value]) => workflows.push(value));

    let isWorkflowAdmin: boolean = false;
    let allLocationsAllowed: boolean = false;
    workflows.forEach((workflow) => {
      const policy = policyMap[workflow];
      const { admin, reviewer, technician, approver, contractor } = policy;
      const locationRestrictedFields = [
        reviewer,
        technician,
        approver,
        contractor,
      ];

      locationRestrictedFields.forEach((x) => {
        if (typeof x !== "boolean")
          locationsIds = [...locationsIds, ...x.locations];
        else {
          if (x === true) allLocationsAllowed = true;
        }
      });

      if (admin) isWorkflowAdmin = true;
      locationsIds = Array.from(new Set(locationsIds));
    });

    if (locationsIds !== undefined) {
      for (let key of locationsIds) {
        const childAndFatherLocationIds = getChildrenId([locationMap[key]]);
        childAndFatherLocationIds.map((id) =>
          locationMap[id].active ? locations.push(locationMap[id]) : null
        );
      }
    }

    if (isProductAdmin || isWorkflowAdmin || allLocationsAllowed)
      return locationsLocalCache;
    else return locations;
  }, [
    isProductAdmin,
    locationMap,
    locationsLocalCache,
    policyMap,
    workflowMap,
    getChildrenId,
  ]);

  const handlePersonFilterChange = useCallback(
    (value: PersonFilterReferenceValue[]) => {
      dispatch(updateRequestFilters({ persons: value }));
    },
    [dispatch]
  );

  const handleLocationFilterChange = useCallback(
    ({ value, lastChecked, clearSelectAll }: LocationFilterValue) => {
      if (clearSelectAll || value.length === 0) {
        const locationsNew = new Set([...value.map((loc) => loc.id)]);
        dispatch(
          updateRequestFilters({
            locations: Array.from(locationsNew).sort(),
          })
        );
        return;
      }
      if (!lastChecked) return;
      const locationsChecked = getChildrenId([locationMap[lastChecked]]);
      if (!locations.includes(lastChecked)) {
        const locationsNew = new Set([...locations, ...locationsChecked]);
        dispatch(
          updateRequestFilters({
            locations: Array.from(locationsNew).sort(),
          })
        );
        return;
      }
      const locationsNew = new Set([
        ...locations.filter((loc) => !locationsChecked.includes(loc)),
      ]);
      dispatch(
        updateRequestFilters({
          locations: Array.from(locationsNew).sort(),
        })
      );
      return;
    },
    [dispatch, getChildrenId, locationMap, locations]
  );

  const handleStatusFilterChange = useCallback(
    (value: ApiRequestStatus[]) => {
      dispatch(updateRequestFilters({ statuses: value }));
    },
    [dispatch]
  );

  const handlePriorityFilterChange = useCallback(
    (value: ApiRequestPriority[]) => {
      dispatch(updateRequestFilters({ priorities: value }));
    },
    [dispatch]
  );

  const handleTypeFilterChange = useCallback(
    (value: ApiRequestType[]) => {
      dispatch(updateRequestFilters({ types: value }));
    },
    [dispatch]
  );

  const handleWorkflowFilterChange = useCallback(
    (value: ApiWorkflow[]) => {
      dispatch(
        updateRequestFilters({
          workflows: value ? value.map((l) => l.id) : [],
        })
      );
    },
    [dispatch]
  );

  const handleCategoryFilterChange = useCallback(
    (value: ApiReportingCategory[]) => {
      const categoryChilds = findAllChildrenForNodesRecursive(value);
      let newValues = Array.from(categoryChilds);
      dispatch(
        updateRequestFilters({
          categories: newValues ? newValues.map((l) => l.id) : [],
        })
      );
    },
    [dispatch, findAllChildrenForNodesRecursive]
  );

  const handleAssetFilterChange = useCallback(
    (value: ApiAsset[]) => {
      dispatch(
        updateRequestFilters({
          assets: value ? value.map((l) => l.id) : [],
        })
      );
    },
    [dispatch]
  );

  const handleEventFilterChange = useCallback(
    (value: ApiEvent[]) => {
      dispatch(
        updateRequestFilters({
          events: value ? value.map((l) => l.id) : [],
        })
      );
    },
    [dispatch]
  );

  const handleScheduledRequestFilterChange = useCallback(
    (value: ApiScheduledRequest[]) => {
      dispatch(
        updateRequestFilters({
          scheduledRequest: value ? value.map((l) => l.id) : [],
        })
      );
    },
    [dispatch]
  );

  const handleServiceFilterChange = useCallback(
    (value: ApiService[]) => {
      dispatch(
        updateRequestFilters({
          services: value ? value.map((l) => l.id) : [],
        })
      );
    },
    [dispatch]
  );
  const handleReasonFilterChange = useCallback(
    (value: ApiReason[]) => {
      dispatch(
        updateRequestFilters({
          reasons: value ? value.map((l) => l.id) : [],
        })
      );
    },
    [dispatch]
  );

  const handleProjectFilterChange = useCallback(
    (value: ApiProject[]) => {
      dispatch(
        updateRequestFilters({
          projects: value ? value.map((l) => l.id) : [],
        })
      );
    },
    [dispatch]
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateRequestFilters({ search: e.target.value }));
    },

    [dispatch]
  );

  const handleChangeMoreFilters = useCallback(
    (values: string[]) => {
      dispatch(updateRequestFilters({ moreFilters: values }));
      const notSelectedFilters = MORE_FILTERS.filter(
        (item) => !values.some((value) => value === item)
      );
      notSelectedFilters.forEach((filter) => {
        dispatch(updateRequestFilters({ [filter]: [] }));
      });
    },
    [dispatch]
  );

  const handleRefreshFilter = useCallback(() => {
    localStorage.removeItem(WORKFLOW_FILTERS);
    dispatch(cleanAllFilters());
  }, [dispatch]);

  useEffect(() => {
    if (
      hasEvents &&
      !MORE_FILTERS.includes("events") &&
      !MORE_FILTERS.includes("services")
    ) {
      MORE_FILTERS.push("events", "services");
    }

    if (hasPlanning && !MORE_FILTERS.includes("projects")) {
      MORE_FILTERS.push("projects");
    }
  }, [hasEvents, hasPlanning]);

  const handleClickFilterCloseIcon = useCallback(
    (e: React.MouseEvent<SVGElement>, filterName: string) => {
      e.preventDefault();
      e.stopPropagation();
      const moreFiltersCopy = [...moreFilters];
      const index = moreFiltersCopy.indexOf(filterName);
      if (index !== -1) {
        moreFiltersCopy.splice(index, 1);
        handleChangeMoreFilters(moreFiltersCopy);
        dispatch(updateRequestFilters({ [filterName]: [] }));
      }
    },
    [dispatch, handleChangeMoreFilters, moreFilters]
  );

  const memoizedFilters: MoreFiltersProps = useMemo(() => {
    return {
      assets: (
        <AssetFilter
          key="assets-filter"
          value={assets}
          onChange={handleAssetFilterChange}
          addCloseIcon={{
            showCloseIcon: true,
            handleClickCloseIcon: handleClickFilterCloseIcon,
          }}
        />
      ),

      reasons: (
        <ReasonFilter
          key="reasons-filter"
          value={reasons}
          onChange={handleReasonFilterChange}
          addCloseIcon={{
            showCloseIcon: true,
            handleClickCloseIcon: handleClickFilterCloseIcon,
          }}
        />
      ),
      types: (
        <TypeFilter
          key="type-filter"
          value={types}
          onChange={handleTypeFilterChange}
          addCloseIcon={{
            showCloseIcon: true,
            handleClickCloseIcon: handleClickFilterCloseIcon,
          }}
        />
      ),
      events: (
        <EventFilter
          key="events-filter"
          value={events}
          onChange={handleEventFilterChange}
          addCloseIcon={{
            showCloseIcon: true,
            handleClickCloseIcon: handleClickFilterCloseIcon,
          }}
        />
      ),
      services: (
        <ServiceFilter
          key="services-filter"
          value={services}
          onChange={handleServiceFilterChange}
          addCloseIcon={{
            showCloseIcon: true,
            handleClickCloseIcon: handleClickFilterCloseIcon,
          }}
        />
      ),
      schedules: (
        <ScheduledRequestFilter
          key="schedules-filter"
          value={scheduledRequest}
          onChange={handleScheduledRequestFilterChange}
          addCloseIcon={{
            showCloseIcon: true,
            handleClickCloseIcon: handleClickFilterCloseIcon,
          }}
        />
      ),
      projects: (
        <ProjectFilter
          key="projects-filter"
          value={projects}
          onChange={handleProjectFilterChange}
        />
      ),
    };
  }, [
    assets,
    handleAssetFilterChange,
    handleProjectFilterChange,
    handleClickFilterCloseIcon,
    reasons,
    handleReasonFilterChange,
    types,
    handleTypeFilterChange,
    events,
    handleEventFilterChange,
    services,
    handleServiceFilterChange,
    scheduledRequest,
    handleScheduledRequestFilterChange,
    projects,
  ]);

  const memoizedMoreFilters = useMemo(() => {
    if (!moreFilters.length) return null;

    const components = moreFilters.map((filter) => {
      return memoizedFilters[filter];
    });

    return components;
  }, [memoizedFilters, moreFilters]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasTechPlus } = useRequestsAccessForUser();

  const switchDisplayMode = useCallback(() => {
    if (displayMode === "column") {
      dispatch(switchMode("row"));
      dispatch(updateRequestFilters({ displayModeFilter: "row" }));
    } else {
      dispatch(switchMode("column"));
      dispatch(updateRequestFilters({ displayModeFilter: "column" }));
    }
  }, [dispatch, displayMode]);

  return !initCompleted ? null : isDesktop ? (
    <>
      <Flex flexDir="row" wrap="wrap" columnGap="2" rowGap="2">
        <WorkflowFilter
          value={workflows}
          onChange={handleWorkflowFilterChange}
        />
        <RequestStatusFilter
          value={statuses}
          onChange={handleStatusFilterChange}
          showSelectAll
          isDisabled={displayMode === "column"}
        />
        <RequestPriorityFilter
          value={priorities}
          onChange={handlePriorityFilterChange}
        />
        <LocationFilter
          value={locations}
          onChange={handleLocationFilterChange}
          allowedLocations={allowedLocations}
        />
        <PersonFilter value={persons} onChange={handlePersonFilterChange} />
        <CategoryFilter
          value={categories}
          onChange={handleCategoryFilterChange}
          isRequestList
        />
        {memoizedMoreFilters && memoizedMoreFilters}
        <FiltersSelector
          value={moreFilters.map((filter) => ({ id: filter, value: filter }))}
          onChange={handleChangeMoreFilters}
          options={MORE_FILTERS}
        />
      </Flex>
      <Box gap={2} flex={1} display="flex" justifyContent="flex-end">
        {hasTechPlus && (
          <IconButton
            onClick={switchDisplayMode}
            icon={
              displayMode === "row" ? <FiColumns /> : <MdOutlineTableRows />
            }
            aria-label="Display view switch"
            bgColor={bgColor}
            colorScheme="blue"
            variant="outline"
            size={isDesktop ? "md" : "sm"}
          />
        )}
        <RequestDateFilter isDisabled={displayMode === "column"} />
        <RequestSortFilter />
        <IconButton
          icon={<Icon as={RiFilterOffLine} />}
          aria-label="Sort"
          bgColor={bgColor}
          colorScheme="blue"
          variant="outline"
          size={isDesktop ? "md" : "sm"}
          onClick={handleRefreshFilter}
        />
      </Box>
    </>
  ) : (
    <>
      <HStack w={"full"} alignItems="right">
        <Button
          leftIcon={isTablet ? <Icon as={RiFilterLine} /> : undefined}
          size={"sm"}
          colorScheme={"blue"}
          onClick={onOpen}
          aria-label={"Filters"}
        >
          Filters
        </Button>
        {hasTechPlus && (
          <IconButton
            onClick={switchDisplayMode}
            icon={
              displayMode === "row" ? <FiColumns /> : <MdOutlineTableRows />
            }
            aria-label="Column View"
            bgColor={bgColor}
            colorScheme="blue"
            variant="outline"
            size={isDesktop ? "md" : "sm"}
          />
        )}
        <RequestDateFilter />
        <RequestSortFilter />
        <IconButton
          icon={<Icon as={RiFilterOffLine} />}
          aria-label="Sort"
          bgColor={bgColor}
          colorScheme="blue"
          variant="outline"
          size={isDesktop ? "md" : "sm"}
          onClick={handleRefreshFilter}
        />
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Select Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex gap={3} flexWrap="wrap">
              <Box width={"full"}>
                <InputGroup flexGrow={1} bgColor={bgColor}>
                  <InputLeftElement
                    children={<SearchIcon color="gray.300" />}
                  />
                  <Input
                    type="text"
                    placeholder="Request #/Summary"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </InputGroup>
              </Box>
              <WorkflowFilter
                value={workflows}
                onChange={handleWorkflowFilterChange}
              />
              <RequestStatusFilter
                value={statuses}
                onChange={handleStatusFilterChange}
                showSelectAll
                isDisabled={displayMode === "column"}
              />
              <RequestPriorityFilter
                value={priorities}
                onChange={handlePriorityFilterChange}
              />
              <LocationFilter
                value={locations}
                onChange={handleLocationFilterChange}
                allowedLocations={allowedLocations}
              />
              <PersonFilter
                value={persons}
                onChange={handlePersonFilterChange}
              />
              <AssetFilter value={assets} onChange={handleAssetFilterChange} />
              <CategoryFilter
                value={categories}
                onChange={handleCategoryFilterChange}
                isRequestList
              />

              <ReasonFilter
                value={reasons}
                onChange={handleReasonFilterChange}
              />

              <TypeFilter value={types} onChange={handleTypeFilterChange} />
              <ScheduledRequestFilter
                value={scheduledRequest}
                onChange={handleScheduledRequestFilterChange}
              />
              {hasEvents && (
                <>
                  <EventFilter
                    value={events}
                    onChange={handleEventFilterChange}
                  />
                  <ServiceFilter
                    value={services}
                    onChange={handleServiceFilterChange}
                  />
                </>
              )}
              {hasPlanning && (
                <ProjectFilter
                  key="projects-filter"
                  value={projects}
                  onChange={handleProjectFilterChange}
                />
              )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
