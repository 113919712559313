export function getUniqueArrayObjects<T extends { id: string }>(
  array: (T | undefined)[]
) {
  if (!array) {
    return [];
  }
  // Create a map to track unique objects by their id
  const uniqueChildObjectsMap = new Map<string, T>();

  array.forEach((object) => {
    if (object && object?.id && !uniqueChildObjectsMap.has(object.id)) {
      uniqueChildObjectsMap.set(object.id, object);
    }
  });

  // Convert the map values back to an array
  return Array.from(uniqueChildObjectsMap.values());
}
