import {
  AccountSettingsKeys,
  ApiAccount,
  ApiAccountSettings,
  ApiAccountSubscription,
  ApiCatalog,
  ApiClient,
  ApiInventoryLocation,
  ApiLocation,
  ApiReason,
  ApiReportingCategory,
  ApiRequestStatus,
  ApiUnitOfMeasure,
  ApiUser,
  ApiVenueSummary,
  ApiWorkflow,
  ApiWorkflowReason,
  ApiWorkflowReportingCategory,
  CatalogPolicy,
  LoginOptions,
  WorkflowPolicy,
  // UserSettingKeys,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IState, State } from "country-state-city";
import { RootState } from ".";
import { buildMap } from "../utils/buildMap";
import {
  SETTING_CLOSED_REQUEST_TIME_PERIOD,
  SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS,
  SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
  SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT,
  HIDE_AI_ASSET_MODAL,
} from "../utils/emailSettingUtils";

import { buildFlattenedTree } from "../utils/flattenedTree";
import { findLocationChildrenFunction } from "../utils/locationUtils";
import { sortArrayByDesc } from "../utils/sortArrayByDesc";
import { getTimeZoneIanaFormat } from "../utils/timezone";
import { initAuth } from "./auth.slice";

export type MenuSection =
  | "account"
  | "requests"
  | "events"
  | "inventory"
  | undefined;
export interface InitLocalCacheThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user?: ApiUser;
}

export interface LoadByWorkflowThunkParams {
  apiClient: ApiClient;
  currentAccount: ApiAccount;
  workflowId: string;
  forceReload?: boolean;
}

const keysToLoad = [
  SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS,
  SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT,
  SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW,
  SETTING_CLOSED_REQUEST_TIME_PERIOD,
  HIDE_AI_ASSET_MODAL,
];
type InventoryView = "full" | "half" | null;

type MapBuilderResult = {
  locationMap: {
    [key: string]: ApiLocation;
  };
  descendantsMap: { [key: string]: string[] };
};

const buildLocationLookupMaps = (locations: ApiLocation[]) => {
  return locations.reduce<MapBuilderResult>(
    (result, location) => {
      result.locationMap[location.id] = { ...location };

      const parents = [...location.treePath.split(".")];

      for (let node of parents) {
        if (!node) continue;
        (result.descendantsMap[node] = result.descendantsMap[node] || []).push(
          location.id
        );
      }

      return result;
    },
    { locationMap: {}, descendantsMap: {} }
  );
};

export const initLocalCache = createAsyncThunk(
  "local-cache/init",
  async ({ apiClient, account, user }: InitLocalCacheThunkParams, thunkAPI) => {
    const { auth } = thunkAPI.getState() as RootState;
    const [
      workflowContexts,
      locations,
      subscriptions,
      reasons,
      categories,
      userSettings,
      catalogContexts,
      unitOfMeasures,
      reports,
      loginSettings,
    ] = await Promise.all([
      apiClient.getCurrentUserWorkflowContexts(account.id),
      apiClient.findLocations(account.id),
      apiClient.getAccountSubscriptions(account.id),
      apiClient.findReasons(account.id, {
        pageSize: 100,
        includeInactive: true,
      }),
      apiClient.findReportingCategories(account.id, {
        includeInactive: true,
      }),
      apiClient.getCurrentUserSettings(account.id, keysToLoad),
      apiClient.getCurrentUserCatalogContexts(account.id),
      apiClient.findUnitOfMeasures(account.id, { includeInactive: true }),
      apiClient.getAccountReportsSummary(account.id),
      apiClient.getAccountSettings(account.id, [
        AccountSettingsKeys.LOGIN_OPTIONS,
      ]),
    ]);
    let userUpdated = user;
    if (user?.timeZone === null) {
      const values = { timeZone: getTimeZoneIanaFormat() };
      await apiClient.updateCurrentUser(values).then((user) => {
        console.log(
          `Current User Timezone has been updated to ${user.timeZone}`
        );
        userUpdated = user;
      });
    }

    const options = State.getStatesOfCountry("US").concat(
      State.getStatesOfCountry("CA")
    ) as IState[];

    const states: Record<string, IState> = {};
    options.forEach((state) => {
      states[state.name] = state;
    });

    let managedVenues: ApiVenueSummary[] = [];
    let managedLocations: ApiLocation[] = [];
    let isVenueManager = false;
    if (auth.isEventStandard && !auth.isEventAdmin) {
      // TODO: create a new endpoint that returns a list of managed by ApiVenueSummary
      //       and a list of the space ID's associated with the venue
      //       add space restrictions (not directly on the venue)

      const fetchVenues = await apiClient.findVenues(account.id, {
        filterByManager: true,
      });
      managedVenues = fetchVenues.data;

      if (managedVenues.length > 0) {
        isVenueManager = true;
        // see todo above
        const venueDetails = await Promise.all([
          ...managedVenues.map((venue) =>
            apiClient.getVenue(account.id, venue.id)
          ),
        ]);

        const venueLocationsIds = Array.from(
          new Set(
            venueDetails
              .flatMap((venue) => venue.spaces)
              .map((location) => location.id)
          )
        );
        managedLocations = locations.filter((location) =>
          venueLocationsIds.includes(location.id)
        );
      }
    }
    const sortedLocations = locations.sort((a, b) =>
      sortArrayByDesc<ApiLocation>(a, b)
    );

    const { locationMap, descendantsMap } = buildLocationLookupMaps(locations);

    return {
      locations: sortedLocations,
      workflowContexts,
      subscriptions,
      reasons,
      categories,
      states,
      userUpdated,
      userSettings,
      managedVenues,
      managedLocations,
      isVenueManager,
      catalogContexts,
      unitOfMeasures,
      reports,
      loginSettings,
      locationMap,
      descendantsMap,
    };
  }
);

export const reloadLocations = createAsyncThunk(
  "local-cache/reload-locations",
  async ({ apiClient, account }: InitLocalCacheThunkParams, thunkAPI) => {
    const locations = await apiClient.findLocations(account.id);
    const sortedLocations = locations.sort((a, b) =>
      sortArrayByDesc<ApiLocation>(a, b)
    );
    return { locations: sortedLocations };
  }
);

export const reloadReasons = createAsyncThunk(
  "local-cache/reload-reasons",
  async ({ apiClient, account }: InitLocalCacheThunkParams, thunkAPI) => {
    const reasons = await apiClient.findReasons(account.id, { pageSize: 100 });

    return { reasons };
  }
);

export const reloadCategories = createAsyncThunk(
  "local-cache/reload-categories",
  async ({ apiClient, account }: InitLocalCacheThunkParams, thunkAPI) => {
    const categories = await apiClient.findReportingCategories(account.id);

    return { categories };
  }
);

export const loadWorkflowReportingCategories = createAsyncThunk(
  "local-cache/load-workflow-categories",
  async (
    {
      apiClient,
      currentAccount,
      workflowId,
      forceReload,
    }: LoadByWorkflowThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const categories = state.localCache.workflowCategories[workflowId];
    // categories already cached, return them.
    if (categories && !forceReload) {
      return { categories, workflowId };
    }

    // fetch categories from api since they are not already in cache
    const apiCategories = await apiClient.findWorkflowReportingCategories(
      currentAccount.id,
      workflowId
    );
    return { categories: buildFlattenedTree(apiCategories), workflowId };
  }
);

export const loadWorkflowReasons = createAsyncThunk(
  "local-cache/load-workflow-reasons",
  async (
    { apiClient, currentAccount, workflowId }: LoadByWorkflowThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const reasons = state.localCache.workflowReasons[workflowId];
    if (reasons) return { reasons, workflowId };
    else {
      const apiReasons = (
        await apiClient.findWorkflowReasons(currentAccount.id, workflowId, {
          pageSize: 100,
        })
      ).data;
      return { apiReasons, workflowId };
    }
  }
);

export const reloadWorkflows = createAsyncThunk(
  "local-cache/reload-workflows",
  async ({ apiClient, account }: InitLocalCacheThunkParams, thunkAPI) => {
    const workflows = await apiClient.findWorkflows(account.id, {
      pageSize: 100,
    });
    return { workflows: workflows.data };
  }
);

export const reloadCatalogs = createAsyncThunk(
  "local-cache/reload-catalogs",
  async ({ apiClient, account }: InitLocalCacheThunkParams, thunkAPI) => {
    const catalogs = await apiClient.findCatalogs(account.id, {
      pageSize: 100,
    });
    return { catalogs: catalogs.data };
  }
);

export const reloadUnitOfMeasures = createAsyncThunk(
  "local-cache/reload-unit-of-measures",
  async ({ apiClient, account }: InitLocalCacheThunkParams, thunkAPI) => {
    const unitOfMeasures = await apiClient.findUnitOfMeasures(account.id);

    return { unitOfMeasures };
  }
);

export const loadStorageLocations = createAsyncThunk(
  "local-cache/reload-storage-locations",
  async ({ apiClient, account }: InitLocalCacheThunkParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    if (state.localCache.storageLocations.length > 0) {
      return state.localCache.storageLocations;
    }
    const result = await apiClient.findInventoryLocations(account.id, {
      pageSize: 100,
    });

    return result.data;
  }
);

export type WithChildren<T> = {
  children: T[];
};

export interface LocalCacheSliceState {
  subscriptions: ApiAccountSubscription[];
  locations: ApiLocation[];
  locationMap: { [key: string]: ApiLocation };
  reasons: ApiReason[];
  workflowReasons: Record<string, ApiWorkflowReason[]>;
  reasonsMap: { [key: string]: ApiReason };
  categories: ApiReportingCategory[];
  workflowCategories: Record<string, ApiWorkflowReportingCategory[]>;
  categoriesMap: { [key: string]: ApiReportingCategory };
  workflows: ApiWorkflow[];
  workflowMap: { [key: string]: ApiWorkflow };
  policyMap: { [key: string]: WorkflowPolicy };
  userSettings: ApiAccountSettings;
  isRequesterOnly: boolean;
  isContractorOnly: boolean;
  isContractor: boolean;
  isApprover: boolean;
  isTechnician: boolean;
  isTechnicianOnly: boolean;
  isReviewer: boolean;
  isAdmin: boolean;
  policyAllowsDashboard: boolean;
  states: Record<string, IState>;
  timeZone: string;
  currentMenuSection: MenuSection;
  inventoryView: InventoryView;
  errorLoading: boolean;
  isVenueManager: boolean;
  managedVenues: ApiVenueSummary[];
  managedLocations: ApiLocation[];
  managedLocationsMap: { [key: string]: ApiLocation };
  catalogs: ApiCatalog[];
  storageLocations: ApiInventoryLocation[];
  catalogMap: { [key: string]: ApiCatalog };
  catalogPolicyMap: { [key: string]: CatalogPolicy };
  isCatalogApprover: boolean;
  isCatalogIssuer: boolean;
  isCatalogFulfiller: boolean;
  isCatalogAdmin: boolean;
  unitOfMeasures: ApiUnitOfMeasure[];
  unitOfMeasureEach: ApiUnitOfMeasure | null;
  reportsSummary: { [key: string]: number };
  loginSettings: LoginOptions;
  descendantsMap: { [key: string]: string[] };
}

export const localCacheSlice = createSlice({
  name: "local-cache",
  initialState: {
    subscriptions: [],
    locations: [],
    workflows: [],
    reasons: [],
    workflowReasons: {} as Record<string, ApiWorkflowReason[]>,
    categories: [],
    workflowCategories: {} as Record<string, ApiWorkflowReportingCategory[]>,
    categoriesMap: {},
    reasonsMap: {},
    locationMap: {},
    workflowMap: {},
    policyMap: {},
    userSettings: {},
    isRequesterOnly: true,
    isContractorOnly: false,
    isContractor: false,
    isApprover: false,
    isTechnician: false,
    isTechnicianOnly: false,
    isReviewer: false,
    isAdmin: false,
    policyAllowsDashboard: false,
    states: {},
    timeZone: getTimeZoneIanaFormat(),
    currentMenuSection: undefined,
    inventoryView: null,
    errorLoading: false,
    isVenueManager: false,
    managedVenues: [],
    managedLocations: [],
    managedLocationsMap: {},
    catalogs: [],
    storageLocations: [],
    catalogMap: {},
    catalogPolicyMap: {},
    isCatalogApprover: false,
    isCatalogIssuer: false,
    isCatalogFulfiller: false,
    isCatalogAdmin: false,
    unitOfMeasures: [],
    unitOfMeasureEach: null,
    reportsSummary: {},
    loginSettings: {},
    descendantsMap: {},
  } as LocalCacheSliceState,
  reducers: {
    unloadCache: (state) => {
      state.locations = [];
      state.workflows = [];
      state.reasons = [];
      state.categories = [];
      state.unitOfMeasures = [];
      state.reportsSummary = {};
      state.catalogs = [];
      state.locationMap = {};
      state.descendantsMap = {};
    },
    addCategoryToLocalCache: (
      state,
      action: PayloadAction<ApiReportingCategory>
    ) => {
      state.categories.unshift(action.payload);
    },
    setErrorLoading: (state, action: PayloadAction<boolean>) => {
      state.errorLoading = action.payload;
    },
    updateCategoryToLocalCache: (
      state,
      action: PayloadAction<ApiReportingCategory>
    ) => {
      const index = state.categories.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index !== -1) {
        state.categories[index] = action.payload;
      }
    },
    addCatalogToLocalCache: (state, action: PayloadAction<ApiCatalog>) => {
      state.catalogs.unshift(action.payload);
    },
    updateCatalogToLocalCache: (state, action: PayloadAction<ApiCatalog>) => {
      const index = state.catalogs.findIndex((x) => x.id === action.payload.id);
      if (index !== -1) {
        state.catalogs[index] = action.payload;
      }
    },
    updateUserSettingToLocalCache: (
      state,
      action: PayloadAction<{
        key: string;
        value: string | number | boolean | Record<string, any>;
      }>
    ) => {
      const { key, value } = action.payload;
      state.userSettings[key] = value;
    },
    setCurrentMenuSection: (
      state,
      action: PayloadAction<{ section: MenuSection }>
    ) => {
      state.currentMenuSection = action.payload.section;
    },
    setInventoryView: (
      state,
      action: PayloadAction<{ view: InventoryView }>
    ) => {
      state.inventoryView = action.payload.view;
    },
    updatePolicyMap: (
      state,
      action: PayloadAction<{
        key: string;
        value: WorkflowPolicy;
      }>
    ) => {
      const newPolicyMap = {
        ...state.policyMap,
        [action.payload.key]: action.payload.value,
      };
      state.policyMap = newPolicyMap;
    },
    updateCatalogPolicyMap: (
      state,
      action: PayloadAction<{
        key: string;
        value: CatalogPolicy;
      }>
    ) => {
      const newPolicyMap = {
        ...state.catalogPolicyMap,
        [action.payload.key]: action.payload.value,
      };
      state.catalogPolicyMap = newPolicyMap;
    },
    updateLoginSettingsToLocalCache: (
      state,
      action: PayloadAction<LocalCacheSliceState["loginSettings"]>
    ) => {
      state.loginSettings = action.payload;
    },
    updateLocations: (state, action: PayloadAction<ApiLocation[]>) => {
      action.payload.forEach((loc) => {
        const index = state.locations.findIndex((l) => l.id === loc.id);
        if (index !== -1) {
          state.locations[index] = loc;
        }
        state.locationMap[loc.id] = loc;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initLocalCache.fulfilled, (state, action) => {
      const {
        locations,
        workflowContexts,
        subscriptions,
        reasons,
        categories,
        states,
        userSettings,
        managedVenues,
        managedLocations,
        isVenueManager,
        catalogContexts,
        unitOfMeasures,
        reports,
        loginSettings,
        locationMap,
        descendantsMap,
      } = action.payload;

      state.subscriptions = subscriptions;

      const workflows: ApiWorkflow[] = [];

      state.states = states;

      const policyMap = workflowContexts.reduce<{
        [key: string]: WorkflowPolicy;
      }>((map, item) => {
        workflows.push(item.workflow);
        map[item.workflow.id] = item.policy;
        return map;
      }, {});
      state.isRequesterOnly =
        Object.keys(policyMap).length > 0 &&
        Object.values(policyMap).every(
          (p) =>
            p.requester &&
            !p.approver &&
            !p.technician &&
            !p.reviewer &&
            !p.admin &&
            !p.contractor
        );
      state.isContractor = Object.values(policyMap).some(
        (p) =>
          p.contractor &&
          !p.approver &&
          !p.technician &&
          !p.reviewer &&
          !p.admin
      );
      state.isContractorOnly =
        Object.keys(policyMap).length > 0 &&
        Object.values(policyMap).every(
          (p) =>
            p.contractor &&
            !p.approver &&
            !p.technician &&
            !p.reviewer &&
            !p.requester &&
            !p.admin
        );

      state.isApprover = Object.values(policyMap).some(
        (p) => p.approver && !p.technician && !p.reviewer && !p.admin
      );

      state.isTechnician = Object.values(policyMap).some(
        (p) => p.technician && !p.reviewer && !p.admin
      );

      state.isTechnicianOnly =
        Object.keys(policyMap).length > 0 &&
        Object.values(policyMap).every(
          (p) =>
            !p.approver &&
            p.technician &&
            !p.reviewer &&
            !p.admin &&
            !p.contractor
        );

      state.isReviewer = Object.values(policyMap).some(
        (p) => p.reviewer && !p.admin
      );

      state.isAdmin = Object.values(policyMap).some((p) => p.admin);

      state.policyAllowsDashboard = state.isReviewer || state.isAdmin;

      state.workflows = workflows;
      state.workflowMap = buildMap(workflows);
      state.policyMap = policyMap;
      state.userSettings = keysToLoad.reduce<ApiAccountSettings>(
        (result, key) => {
          if (key === HIDE_AI_ASSET_MODAL && userSettings[key] === undefined) {
            result[key] = false;
            return result;
          }
          if (
            key === SETTING_CLOSED_REQUEST_TIME_PERIOD &&
            userSettings[key] === undefined
          ) {
            result[key] = "last7d";
            return result;
          }

          if (key === SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT) {
            result[key] =
              userSettings[key] === undefined
                ? false
                : (userSettings[key] as boolean);
            return result;
          }

          if (key === SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW) {
            if (userSettings[key] !== undefined) {
              result[key] = userSettings[key];
              return result;
            }
            const defaultVisibilitySettings = (
              Object.keys(ApiRequestStatus) as ApiRequestStatus[]
            ).reduce(
              (map, statusKey) => {
                map[statusKey] = true;
                return map;
              },
              {} as { [key in ApiRequestStatus]?: boolean }
            );
            result[key] = defaultVisibilitySettings;
            return result;
          }

          result[key] =
            userSettings[key] === undefined
              ? true
              : (userSettings[key] as boolean);
          return result;
        },
        {}
      );

      const loginOptions =
        (loginSettings[AccountSettingsKeys.LOGIN_OPTIONS] as LoginOptions) ??
        {};

      state.loginSettings = {
        "show-email-and-password":
          loginOptions["show-email-and-password"] ?? true,
        "show-google": loginOptions["show-google"] ?? true,
        "show-microsoft": loginOptions["show-microsoft"] ?? true,
      };

      state.locations = sortLocations(locations);
      state.locationMap = locationMap;
      state.descendantsMap = descendantsMap;
      state.reasons = reasons.data;
      state.reasonsMap = buildMap(reasons.data);
      state.categories = buildFlattenedTree(categories);
      state.categoriesMap = buildMap(state.categories);
      state.isVenueManager = isVenueManager;

      if (isVenueManager) {
        state.managedVenues = managedVenues;
        const locationsTree = getLocationsForManager(
          locationMap,
          descendantsMap,
          managedLocations
        );
        state.managedLocations = sortLocations(locationsTree);
        state.managedLocationsMap = buildMap(locationsTree);
      }
      const catalogs: ApiCatalog[] = [];
      const catalogPolicyMap = catalogContexts.reduce<{
        [key: string]: CatalogPolicy;
      }>((map, item) => {
        catalogs.push(item.catalog);
        map[item.catalog.id] = item.policy;
        return map;
      }, {});
      state.catalogs = catalogs;
      state.catalogMap = buildMap(catalogs);
      state.catalogPolicyMap = catalogPolicyMap;

      state.isCatalogApprover = Object.values(catalogPolicyMap).some(
        (p) => p.approver && !p.admin
      );
      state.isCatalogIssuer = Object.values(catalogPolicyMap).some(
        (p) => p.issuer && !p.admin
      );
      state.isCatalogFulfiller = Object.values(catalogPolicyMap).some(
        (p) => p.fulfillment && !p.admin
      );
      state.isCatalogAdmin = Object.values(catalogPolicyMap).some(
        (p) => p.admin
      );

      state.unitOfMeasures = unitOfMeasures;
      const unitOfMeasureEach = state.unitOfMeasures.find(
        (unitOfMeasure) =>
          unitOfMeasure.isSystem &&
          unitOfMeasure.unit === "each" &&
          unitOfMeasure.name === "Each"
      );
      state.unitOfMeasureEach = unitOfMeasureEach ? unitOfMeasureEach : null;

      /** Reports */
      state.reportsSummary = reports;
    });

    builder.addCase(reloadLocations.fulfilled, (state, action) => {
      state.locations = sortLocations(action.payload.locations);
      const { locationMap, descendantsMap } = buildLocationLookupMaps(
        action.payload.locations
      );
      state.locationMap = locationMap;
      state.descendantsMap = descendantsMap;
    });

    builder.addCase(
      loadWorkflowReportingCategories.fulfilled,
      (state, action) => {
        const workflowId = action.payload.workflowId;
        state.workflowCategories[workflowId] = action.payload.categories;
      }
    );

    builder.addCase(initAuth.rejected, (state, action) => {
      state.errorLoading = true;
    });

    builder.addCase(initLocalCache.rejected, (state, action) => {
      state.errorLoading = true;
    });

    builder.addCase(loadWorkflowReasons.fulfilled, (state, action) => {
      const workflowId = action.payload.workflowId;
      if (action.payload.apiReasons) {
        state.workflowReasons[workflowId] = action.payload.apiReasons;
      } else {
        if (action.payload.reasons) {
          state.workflowReasons[workflowId] = action.payload.reasons;
        }
      }
    });

    builder.addCase(reloadWorkflows.fulfilled, (state, action) => {
      const { workflows } = action.payload;
      state.workflows = workflows;
      state.workflowMap = buildMap(workflows);
    });

    builder.addCase(reloadCatalogs.fulfilled, (state, action) => {
      const { catalogs } = action.payload;
      state.catalogs = catalogs;
      state.catalogMap = buildMap(catalogs);
    });

    builder.addCase(loadStorageLocations.fulfilled, (state, action) => {
      state.storageLocations = action.payload;
    });
  },
});

const getLocationsForManager = (
  locationMap: { [key: string]: ApiLocation },
  descendantsMap: { [key: string]: string[] },
  managedLocations: ApiLocation[]
) => {
  const childrenLocationsSet = new Set<string>();
  const venueChildrenIds = Array.from(
    findLocationChildrenFunction(
      descendantsMap,
      managedLocations.map((l) => l.id),
      childrenLocationsSet
    )
  );

  // after splitting the treepath, we keep only valid values
  const cleanedLocations = managedLocations.map((location) =>
    location.treePath.split(".").filter((location) => location)
  );

  const getParentsOnly = cleanedLocations.map((locations) => {
    if (locations.length > 0) return locations.slice(0, locations.length - 1);
    return [];
  });

  const venueParentsIds = getParentsOnly.flat();
  const treeIds = new Set([
    ...venueChildrenIds,
    ...venueParentsIds,
    ...managedLocations.map((l) => l.id),
  ]);
  const treeLocations = Array.from(treeIds).map<ApiLocation>(
    (parentId) => locationMap[parentId]
  );
  return treeLocations;
};

const sortLocations = (
  locations: ApiLocation[],
  parentId: string | null = null
) => {
  const nodes = locations
    .filter((x) => x.parent === parentId)
    .sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1));

  return nodes.reduce<ApiLocation[]>((result, item) => {
    result.push(item);

    result.push(...sortLocations(locations, item.id));

    return result;
  }, []);
};

// Action creators are generated for each case reducer function
export const {
  unloadCache,
  setErrorLoading,
  setInventoryView,
  setCurrentMenuSection,
  addCategoryToLocalCache,
  updateCategoryToLocalCache,
  updateUserSettingToLocalCache,
  updatePolicyMap,
  updateCatalogPolicyMap,
  addCatalogToLocalCache,
  updateCatalogToLocalCache,
  updateLoginSettingsToLocalCache,
  updateLocations,
} = localCacheSlice.actions;

export default localCacheSlice.reducer;
