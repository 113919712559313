import { RequestListFilterState } from "../request-list.slice";

export const REQUEST_LIST_FILTERS = "request-list-filters";
export const REQUEST_LIST_TITLE = "listTitle";
export const DEFAULT_PAGE_SIZE = 50;

export const filtersInitialState: RequestListFilterState = {
  workflows: [],
  statuses: [],
  priorities: [],
  locations: [],
  assignees: [],
  requesters: [],
  categories: [],
  reasons: [],
  created: [],
  updated: [],
  statusUpdated: [],
  start: [],
  due: [],
  completed: [],
  persons: [],
  date: null,
  dateRelative: undefined,
  currentPage: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  search: "",
  quickFilter: undefined,
  types: [],
  assets: [],
  events: [],
  services: [],
  scheduledRequest: [],
  moreFilters: [],
  displayModeFilter: undefined,
  projects: [],
};
